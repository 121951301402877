import {
  store
} from "@/store/store.js";
import {
  colors
} from "@/core/colors.js";

export const colorMixin = {
  store: store,
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
};