<template>
  <div class="coding-container">
    <div class="right-side">
      <Item
        v-for="(item, index) in illustrationData"
        :key="index"
        :title="item.title"
        :date="item.date"
        :description="item.description"
        :image="isDark ? images.dark[index] : images.light[index]"
      />
    </div>
    <!-- Right Side -->
  </div>
</template>

<script>
import { basicInnerPageMixin } from "@/core/mixins/inner_page_mixin.js";
import { writtenData } from "@/core/mixins/written_data_mixin.js";
import { colorMixin } from "@/core/mixins/color_mixin.js";
import Item from "@/pages/illustration/desktop/Item.vue";
import lightIcons from "@/assets/images/illustrations/light/system_icons.webp";
import darkIcons from "@/assets/images/illustrations/dark/system_icons_dark.webp";
import lightAlbum from "@/assets/images/illustrations/light/family_album.webp";
import darkAlbum from "@/assets/images/illustrations/dark/family_album_dark.webp";
import darkSystemIcons from "@/assets/images/illustrations/dark/system_icons_dark.webp";
import lightCover from "@/assets/images/illustrations/light/teen_cover.webp";
import darkCover from "@/assets/images/illustrations/dark/teen_cover.webp";
import lightParrot from "@/assets/images/illustrations/light/parrot.webp";
import darkParrot from "@/assets/images/illustrations/dark/parrot_dark.webp";
import lightClimber from "@/assets/images/illustrations/light/rock_climber.webp";
import darkClimber from "@/assets/images/illustrations/dark/rock_climber_dark.webp";
import lightHalloween from "@/assets/images/illustrations/light/halloween_poster.webp";
import darkHalloween from "@/assets/images/illustrations/dark/halloween_poster_dark.webp";
import lightReading from "@/assets/images/illustrations/light/girl_reading.webp";
import darkReading from "@/assets/images/illustrations/dark/girl_reading.webp";

export default {
  mixins: [basicInnerPageMixin, writtenData, colorMixin],
  components: {
    Item,
  },
  data() {
    return {
      images: {
        light: [
          lightIcons,
          lightAlbum,
          lightCover,
          lightParrot,
          lightClimber,
          lightHalloween,
          lightReading,
        ],
        dark: [
          darkIcons,
          darkAlbum,
          darkCover,
          darkParrot,
          darkClimber,
          darkHalloween,
          darkReading,
        ],
      },
    };
  },
};
</script>

<style scoped>
.coding-container {
  width: 100vw;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 18.25rem 56rem;
}
.break-down {
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 4rem;
}
.body {
  display: grid;
  grid-template-rows: 14rem auto;
}
.right-side {
  width: min-content;
  display: grid;
  grid-template-rows: auto;
  grid-gap: 5rem;
  padding: 9rem 2rem;
}
</style>