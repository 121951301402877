<template>
  <div :class="{'no-header-image': headerLayout}">
    <h1 v-if="title" :style="{color: myColors.main}" :class="h2Class">{{title}}</h1>
    <h3 v-if="subtitle" :style="{color: myColors.main}" class="work-item-subhead">{{subtitle}}</h3>
    <p :style="{color: myColors.foreground}">{{description}}</p>
  </div>
</template>
<script>
import { store } from "@/store/store.js";
import { colors } from "@/core/colors.js";

export default {
  store: store,
  props: {
    title: {
      type: String,
    },
    description: {
      default: "Body",
      type: String,
    },
    subtitle: {
      type: String,
    },
    titleType: {
      default: "spaced-out",
      type: String,
    },
  },

  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
    h2Class: function () {
      if (this.titleType == "spaced-out") {
        if (window.innerWidth < 480) {
          return "work-item-header-mobile";
        } else {
          return "work-item-header";
        }
      } else {
        return "work-item-header-alt";
      }
    },
    headerLayout: function () {
      if (this.type == "noHeaderImage") {
        return "subtitle-body-container no-header-image";
      } else {
        return "subtitle-body-container";
      }
    },
  },
};
</script>
<style scoped>
.subtitle-body-container {
  text-align: left;
  display: grid;
}
.no-header-image {
  margin: 4.625rem 0 0 0;
}
.work-item-subhead {
  margin: 0 0 2rem 0 !important;
}
@media (max-width: 480px) {
  .subtitle-body-container {
    width: calc(100vw - 4rem);
  }
  .work-item-subhead {
    margin: 0 0 2rem 2rem !important;
  }
  h1,
  p {
    margin: 0 0 2rem 0;
  }
  .work-item-subhead {
    margin: 0 0 2rem 0 !important;
  }
  .no-header-image {
    padding: 0 2rem;
    width: calc(100vw - 2rem);
    display: grid;
    box-sizing: border-box;
  }
}
</style>