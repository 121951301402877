<template>
  <button
    v-on:mouseover="renderHover"
    v-on:mouseleave="renderNormal"
    @click="scrollToTop"
    :class="['to-top', isDark ? 'dark' : 'light']"
  >
    <UpArrow :userHasHovered="hasHovered" />
    <p :style="{ color: myColors.onBackground }">back to top</p>
  </button>
</template>

<script>
import { colors } from "@/core/colors.js";
import UpArrow from "@/components/svg/UpArrow.vue";
import {store} from "@/store/store";
export default {
  store: store,
  components: {
    UpArrow,
  },
  computed: {
    isDark: function () {
      return this.$store.getters.isDark;
    },
    myColors: function () {
      return colors(this.$store.getters.isDark, this.$store);
    },
  },
  methods: {
    renderHover: function () {
      this.hasHovered = true;
    },
    renderNormal: function () {
      this.hasHovered = false;
    },
    scrollToTop: function () {
      window.scrollTo(0, 0);
    },
  },
  data() {
    return {
      hasHovered: false,
    };
  },
};
</script>

<style scoped>
.dark {
  background-color: var(--color-background-primary-dark-on-background);
}
.light {
  background-color: var(--color-orange-2);
}
.dark:hover {
  background-color: var(--color-gray-200);
}
button {
  backdrop-filter: opacity(0.3);
  padding-block: 0.3rem;
  block-size: fit-content;
  box-sizing: border-box;
  border-radius: 0.2rem;
}
</style>
