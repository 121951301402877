export interface Dimensions {
    height?: string,
    width?: string,
    top?: string,
    left?: string
}

export interface Mode {
    small?: Dimensions,
    xSmall?: Dimensions
}

export interface Box {
    mode: Mode
}

export interface TextPosition {
    small?: Dimensions,
    xSmall?: Dimensions
}

export interface Boxes {
    topBox: Box,
    bottomBox: Box
}

export const textPostion: TextPosition = {
    xSmall: {
        top: "8rem",
        left: "9rem"
    },
    small: {
        top: "",
        left: "initial"
    }
}

export const boxes: Boxes = {
    topBox: {
        mode: {
            small: {
                height: "8rem",
                width: "8rem",
                left: "8rem",
                top: "11.625rem"
            },
            xSmall: {
                height: "3.437rem",
                width: "3.437rem",
                top: "9rem",
                left: "9rem"
            }

        }
    },
    bottomBox: {
        mode: {
            small: {
                height: "2.437rem",
                width: "2.125rem"
            },
            xSmall: {
                height: "1.062rem",
                width: "1.062rem",
                top: "12rem",
                left: "7.5rem"
            }
        }
    }
}