import SectionHeader from "@/components/section_header/SectionHeader.vue";
import BackToTop from "@/components/controls/button/BackToTop.vue";
import SubtitleBody from "@/components/info/desktop/Subtitle_Body.vue";

export const basicInnerPageMixin = {
    components: {
        SectionHeader,
        BackToTop,
        SubtitleBody
    }
}