import {
    data
} from '@/core/data/index.ts';
export const writtenData = {
    computed: {
        codingData() {
            return data.written.projects.coding
        },
        uxProjects() {
            return [
                data.written.projects.sentinel,
                data.written.projects.bibleLesson,
                data.written.projects.SDdesignSystem,
            ]
        },
        illustrationData() {
            let modData = data.written.projects.illustrations;
            return data.written.projects.illustrations
        }
    },
}