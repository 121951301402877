<template>
  <div class="illustration-item">
    <div class="written">
      <h3 :style="{ color: myColors.main }">{{ title }}</h3>
      <p :style="{ color: myColors.onBackground }">{{ date }}</p>
      <p v-if="description" :style="{ color: myColors.foreground }">
        {{ description }}
      </p>
    </div>
    <img v-if="image" :src="image" alt srcset />
  </div>
</template>

<script>
import { colorMixin } from "@/core/mixins/color_mixin.js";

export default {
  mixins: [colorMixin],
  props: {
    title: {
      type: String,
      default: "Title here",
    },
    image: {
      type: String,
      default: "Title here",
    },
    date: {
      type: String,
      default: "Date here",
    },
    description: {
      type: String,
    },
  },
};
</script>

<style scoped>
h3 {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.625rem;
  inline-size: 100%;
}

p {
  inline-size: 100%;
}

.written {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 1.25rem;
  inline-size: 100%;
}

.illustration-item {
  display: grid;
  grid-template-rows: auto;
  grid-gap: 5rem;
}

.illustration-item > * {
  text-align: left;
}

img {
  object-fit: contain;
  max-width: 56rem;
  inline-size: 100%;
  border-radius: 1rem;
}

@media (max-width: 480px) {
  img {
    width: calc(100vw - 4rem);
    max-width: calc(100vw - 4rem);
    min-width: calc(100vw - 4rem);
  }
}
</style>
